import cls from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2, P } from '@/components/atoms/Typography/Typography';
import { CloudinaryImage } from '@/components/local/CloudinaryImage/CloudinaryImage';
import { NavLink } from '@/components/local/NavLink/NavLink';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { textAndImageSize } from '@/containers/HomePage/textAndImageSizes';
import { ProductKeys } from '@/data/productsConfig';
import { useData } from '@/lib/pageData/pageDataContext';
import styles from './SwapfietsService.module.scss';

export const SwapfietsService = ({ className }: { className?: string }) => {
  const { t } = useTranslation(nameSpacesConfig.common);
  const { page, navLinks } = useData<'home' | 'city' | ProductKeys>();
  const serviceLinkLabel = t('service.link');
  return (
    <TextAndImage
      className={cls(styles.container, className)}
      classLeft={styles.left}
      classRight={cls('typography', styles.right)}
      wrapOnTop="left"
      id="swapfiets-service"
      left={
        <CloudinaryImage
          alt={t('service.image.alt')}
          src={page.images.swapfietsService}
          layout="fill"
          objectFit="cover"
          objectPosition="35% 50%"
          sizes={textAndImageSize}
        />
      }
      right={
        <>
          <H2>{t('service.heading')}</H2>
          <P>
            <Trans i18nKey="service.text" components={{ bold: <strong /> }} />
          </P>
          {serviceLinkLabel && (
            <NavLink link={navLinks.locations} withArrow>
              {serviceLinkLabel}
            </NavLink>
          )}
        </>
      }
    />
  );
};
