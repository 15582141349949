import { NextPageContext } from 'next';

type ErrorSignature = (
  error: Error | NextPageContext['err'] | string,
  additionalContext?: Record<string, unknown> | string
) => void;

type LoggerType = {
  warn: ErrorSignature;
  error: ErrorSignature;
};

let Logger: LoggerType;

if (process.env.NEXT_PUBLIC_NEW_RELIC_ENABLED === 'true') {
  Logger = {
    warn: (error, additionalContext) => {
      // -TODO  Still need to find a solution for warnings
      // eslint-disable-next-line
      console.log(error, additionalContext);
    },
    error: error => {
      if (typeof window === 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const newrelic = require('newrelic');
        newrelic.noticeError(error);
      } else {
        window?.NREUM?.noticeError(error);
      }
    },
  };
} else {
  Logger = {
    // eslint-disable-next-line
    warn: error => console.log(error),
    // eslint-disable-next-line
    error: error => console.log(error),
  };
}

export { Logger };

// I keep it in case we want to use winston at somepoint (Unfortunatelly is only for the server)
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const winston = require('winston');
// const logger = winston.createLogger({
//   transports: [new winston.transports.Console()],
// });
// logger.error(error, {
//   application: 'NextJs NewRelic app logging',
//   test: 'Testing logging with Winston',
//   // pathname: ctx.pathname,
// });
