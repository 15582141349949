import { DYNAMIC_CONFIG_NAMES, getConfig } from '@/lib/statsig';
import { AssetCategoryResult } from '@/types/AssetCategoryResult';
import { Countries } from '@/types/Countries';

export const isNotificationBannerHidden = (countryCode: Countries) => {
  const config = getThreeTierProductsConfig(countryCode);

  return config?.get<boolean>('isNotificationBannerHidden', false);
};

export const isThreeTierProduct = (
  countryCode: Countries,
  productCode: string
) => {
  const config = getThreeTierProductsConfig(countryCode);

  return config?.get<string[]>('products', []).includes(productCode);
};

export const getThreeTierProductsConfig = (countryCode: Countries) => {
  return getConfig(DYNAMIC_CONFIG_NAMES.threeTierPricingProductsConfig, {
    custom: {
      country: countryCode,
    },
  });
};

export const getThreeTierExperimentProduct = <T extends AssetCategoryResult>(
  countryCode: Countries,
  product?: T
) => {
  const config = getThreeTierProductsConfig(countryCode);
  const rates = config?.get<{
    [key: string]: AssetCategoryResult['rates'];
  } | null>('rates', null);

  if (!product || !rates) return undefined;

  return {
    ...product,
    rates:
      product.assetCategoryCode !== 'pwr-plus'
        ? rates[product.assetCategoryCode]
        : product.rates,
  };
};

export const getThreeTierExperimentProducts = <T extends AssetCategoryResult>(
  countryCode: Countries,
  products: T[]
) => {
  const config = getThreeTierProductsConfig(countryCode);
  const rates = config?.get<{
    [key: string]: AssetCategoryResult['rates'];
  } | null>('rates', null);

  if (!rates) return undefined;

  return products.map(product => ({
    ...product,
    rates:
      product.assetCategoryCode !== 'pwr-plus'
        ? rates[product.assetCategoryCode]
        : product.rates,
  }));
};
