import { ProductKeys } from '@/data/productsConfig';
import { testRideBikes } from '@/data/testRideConfig';
import { CityData } from '@/types/CityData';
import { Countries } from '@/types/Countries';

export const shouldShowTestRideCta = (
  country: Countries,
  city: CityData,
  bikeId: ProductKeys
) => !!testRideBikes?.[country]?.[bikeId]?.[city.code];

export const shouldShowTestRideBikes = (
  country: Countries,
  bikeId: ProductKeys
) => !!testRideBikes?.[country]?.[bikeId];
