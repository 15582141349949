import localesConfig from '@/config/config.locales.json';
import { Countries } from '@/types/Countries';
import { addSchemeToDomain } from '@/utils/addSchemeToDomain';

interface GetAlternateList {
  domain: string;
  hrefLang: string;
}

interface Params {
  subPath?: string;
  filterCountry?: Countries;
}
/*
  * This function is generating a list of domains and hrefLangs for displaying <link rel="alternate" hreflang="lang_code"... >
int the page head.
  * The list can be filtered by country because deep routs often do not have versions in other languages (/paris /milan)
  * Based on https://developers.google.com/search/docs/advanced/crawling/localized-versions
  *
  * NOTE: Function generates an array not JSX intentionally - next.js page/head component doesn't rerender properly when passing JSX as prop
* */
export function getAlternateList({ subPath = '', filterCountry }: Params = {}) {
  return Object.entries(localesConfig).reduce<GetAlternateList[]>(
    (array, [key, entry]) => {
      if (filterCountry && filterCountry != key) {
        return array;
      }

      entry.locales.forEach(locale => {
        const alternateDomain = new URL(
          (locale.key === entry.defaultLocale ? '' : `/${locale.key}/`) +
            subPath,
          addSchemeToDomain(entry.domain)
        ).toString();
        array.push({
          domain: alternateDomain,
          hrefLang: locale.key,
        });
      });
      return array;
    },
    []
  );
}
