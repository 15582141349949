import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { A } from '@/components/atoms/A/A';
import { TextAndImage } from '@/components/atoms/TextAndImage/TextAndImage';
import { H2 } from '@/components/atoms/Typography/Typography';
import { NavLink } from '@/components/local/NavLink/NavLink';
import { Faq } from '@/components/molecules/Faq';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { useData } from '@/lib/pageData/pageDataContext';
import { Pages } from '@/types/PageData';
import styles from './PageFaq.module.scss';

export const PageFaq = () => {
  const { t } = useTranslation([nameSpacesConfig.faq, nameSpacesConfig.common]);
  const data = useData<Exclude<Pages, 'checkout'>>();

  const translatedFaqItems =
    data.page?.faqList?.map(entry => {
      const navLink = entry.link ? (
        <NavLink link={data.navLinks[entry.link]} color="primary" key="link">
          link
        </NavLink>
      ) : entry.customLink ? (
        <A target="_blank" href={entry.customLink} color="primary" />
      ) : null;

      return {
        id: entry.id,
        question: t(`faq:${entry.id}.q`),
        answer: (
          <Trans
            i18nKey={`faq:${entry.id}.a`}
            values={{ link: t('common:navLinks.stores') }}
            components={{
              navLink,
              bold: <strong />,
              table: <table style={{ width: '100%' }} />,
              td: <td />,
              tr: <tr />,
            }}
          />
        ),
      };
    }) ?? [];

  return (
    <TextAndImage
      className={styles.container}
      classLeft={styles.leftSide}
      left={
        <>
          <H2>{t('faq:faq.headingMain')}</H2>
        </>
      }
      right={<Faq items={translatedFaqItems} version="level4" />}
    />
  );
};
