import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import Script from 'next/script';
import React from 'react';
import { useBoolean } from 'react-use';
import { Button, ButtonProps } from '@/components/atoms/Button/Button';
import { ContentDialog } from '@/components/organisms/ContentDialog';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { testRideBikes, TestRideBikes } from '@/data/testRideConfig';
import { Countries } from '@/types/Countries';
import { TestRideContentIframe } from './components/TestRideIframe';
import styles from './TestRideDialog.module.scss';

const TestRideScript: React.FC = () => (
  <Script
    src="https://embed.acuityscheduling.com/js/embed.js"
    strategy="lazyOnload"
    key="test-ride"
  />
);

interface Props {
  bike: TestRideBikes;
  country: Countries;
  ctaProps?: ButtonProps;
  productAssetCategoryCode: string;
  testRideCode: string;
}

export const TestRideDialog = ({
  bike,
  country,
  ctaProps,
  productAssetCategoryCode,
  testRideCode,
}: Props) => {
  const { t } = useTranslation(nameSpacesConfig.common);
  const [isOpen, toggleIsOpen] = useBoolean(false);

  const testRideCity = testRideBikes?.[country]?.[bike]?.[testRideCode];
  const close = () => toggleIsOpen(false);

  return (
    <>
      <Button
        data-gtag={`E-bike test ride ${productAssetCategoryCode}`}
        variant="outlined"
        color="secondary"
        {...ctaProps}
        className={cls(styles.openDialog, ctaProps?.className)}
        onClick={e => {
          ctaProps?.onClick?.(e);
          toggleIsOpen(true);
        }}
      >
        {t('testRide.cta')}
      </Button>
      <ContentDialog
        isOpen={isOpen}
        handleClose={close}
        ariaLabels={{ dialog: 'Test Ride', close: 'Close' }}
        dialogClass={styles.dialog}
        isOverlayDismissible={false}
      >
        <TestRideContentIframe testRideCity={testRideCity} />
      </ContentDialog>
      <TestRideScript />
    </>
  );
};
