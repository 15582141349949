import { useTranslation } from 'next-i18next';
import React, { useState, useEffect } from 'react';
import { Button } from '@/components/atoms/Button/Button';
import { H3, P } from '@/components/atoms/Typography/Typography';
import { ChevronBlue } from '@/components/Icons/ChevronBlue';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { TestRideLocation } from '@/data/testRideConfig';
import styles from '../TestRideDialog.module.scss';

const Steps = {
  selectStore: 'selectStore',
  selectAppointment: 'selectAppointment',
} as const;

type Step = keyof typeof Steps;

type TestRideContentIframeProps = {
  testRideCity?: TestRideLocation[];
};

export const TestRideContentIframe = ({
  testRideCity,
}: TestRideContentIframeProps) => {
  const [storeNumber, setStoreNumber] = useState<number>();
  const [step, setStep] = useState<Step>(
    hasMultipleStores(testRideCity)
      ? Steps.selectStore
      : Steps.selectAppointment
  );

  useEffect(() => {
    if (hasMultipleStores(testRideCity)) {
      return () => {
        setStep(Steps.selectStore);
      };
    }
  }, [testRideCity]);

  const handleChangeStore = (appointmentType?: number) => {
    setStoreNumber(appointmentType);
    setStep(Steps.selectAppointment);
  };

  if (hasMultipleStores(testRideCity) && step === Steps.selectStore) {
    return (
      <SelectStoreStep
        testRideCities={testRideCity}
        handleChangeStore={handleChangeStore}
      />
    );
  }

  return (
    <TestRideIframe
      handleChangeStore={() => setStep(Steps.selectStore)}
      testRideCity={testRideCity}
      storeNumber={storeNumber}
    />
  );
};

type TestRideIframeProps = {
  testRideCity?: TestRideLocation[];
  storeNumber?: number;
  handleChangeStore: () => void;
};

const TestRideIframe = ({
  storeNumber,
  testRideCity,
  handleChangeStore,
}: TestRideIframeProps) => {
  const { t } = useTranslation(nameSpacesConfig.common);

  const config = hasMultipleStores(testRideCity)
    ? testRideCity?.find(a => a.appointmentType === storeNumber)
    : testRideCity?.[0];

  if (!config) return null;

  return (
    <div className={styles.iframeContainer}>
      {hasMultipleStores(testRideCity) && (
        <Button onClick={handleChangeStore} className={styles.changeStore}>
          <ChevronBlue width={20} height={20} />
          <P>{t('testRide.selectStore.goBack')}</P>
        </Button>
      )}
      <iframe
        src={`https://app.squarespacescheduling.com/schedule.php?owner=${
          config.owner
        }${
          config.appointmentType
            ? `&appointmentType=${config.appointmentType}`
            : ''
        }`}
        title="Schedule Appointment"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  );
};

type SelectStoreStepProps = {
  testRideCities?: TestRideLocation[];
  handleChangeStore: (appointmentType?: number) => void;
};

const SelectStoreStep = ({
  handleChangeStore,
  testRideCities,
}: SelectStoreStepProps) => {
  const { t } = useTranslation(nameSpacesConfig.common);

  return (
    <div className={styles.storeStepContainer}>
      <div className={styles.storeStepInner}>
        <H3> {t('testRide.selectStore.title')}</H3>
        {testRideCities?.map((store, index) => (
          <div className={styles.selectStore} key={index}>
            <div>
              <P className={styles['location-bold']}>{store?.location}</P>
              <P>{store?.address}</P>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleChangeStore(store?.appointmentType)}
            >
              {t('testRide.book')}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const hasMultipleStores = (testRideCity?: TestRideLocation[]): boolean =>
  !!testRideCity && testRideCity.length > 1;
